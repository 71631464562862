// external libraries
import classNames from "classnames";

// icons
import CircleIcon from "@icons/circle.svg?react";
import CheckCircleIcon from "@icons/check-circle.svg?react";


type HDCheckBoxProps = {
    isChecked: boolean
}

/**
 * HDCheckBox
 * A component that displays a circle icon or a check circle icon,
 * as a reusuable HD styled checkbox.
 * @param {boolean} isChecked - Whether the checkbox is checked
 */
const HDCheckBox = ({ isChecked }: HDCheckBoxProps) => {
    return (
        <div className={classNames("w-6 h-6", isChecked ? "text-max-700" : "text-shadow-500")}>
            {isChecked ? <CheckCircleIcon /> : <CircleIcon />}
        </div>
    );
}

export default HDCheckBox;