function Divider(
	{
		customClassNames = '',
	}
) {
	return (
		<div className={customClassNames}>
			<div className=" border-[1px] border-gray-200 text-gray-200" />
		</div>
	);
}

export default Divider;
