import { useState, useEffect } from "react";
import { setPatientRecordStage } from "../lib/supabaseClient";
import { useDataContextApi } from "@providers/DataProvider";
import { PatientRecord, PatientStage } from "../utils/types";
import { useLocation, useNavigate } from "react-router-dom";
import { resetSelectedTableRecords, updateTableRecord } from "@redux/patientTableRecordsSlice";
import ChevronRightIcon from "@icons/chevron-right.svg?react";
import ChevronLeftIcon from "@icons/chevron-left.svg?react";
import { setCompleting } from "@/state/redux/globalState";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";

const CompleteDocuments = () => {
	const { selectedTableRecords } = useAppSelector((state) => state.patientTableRecords);
	const { completing, } = useAppSelector((state) => state.globalState);
	const { isLoadingData } = useDataContextApi();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [docsToBeCompleted, setDocsToBeCompleted] = useState([] as PatientRecord[]);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		setDocsToBeCompleted(selectedTableRecords as PatientRecord[]);
	}, [selectedTableRecords]);

	const handleBack = (index: number) => {
		if (index > 0) {
			setCurrentIndex(index - 1);
			navigate(`/appointments/${docsToBeCompleted[index - 1]?.id}`, {
				state: { appointmentId: docsToBeCompleted[index - 1]?.id },
			});
		}
	};
	//handle cancel button
	const handleCancel = () => {
		dispatch(setCompleting(false))
		const url = pathname.split("/");
		const isOnIndividualAppointmentPage = url[1] === "appointments";
		dispatch(resetSelectedTableRecords());
		if (isOnIndividualAppointmentPage) {
			navigate("/");
			setCurrentIndex(0);
		}
	};

	const markAsComplete = async (index: number) => {
		dispatch(
			updateTableRecord({
				id: docsToBeCompleted[index].id as string,
				updatedRecord: {
					id: docsToBeCompleted[index].id!,
					stage: PatientStage.Finalized,
				},
			}),
		);

		await setPatientRecordStage(docsToBeCompleted[index].id as string, PatientStage.Finalized);

		if (currentIndex === docsToBeCompleted.length - 1) {
			// user has completed all documents
			dispatch(setCompleting(false))
			setDocsToBeCompleted([]);
			dispatch(resetSelectedTableRecords());
			setCurrentIndex(0);
			navigate("/");
		} else {
			setCurrentIndex(index + 1);
			navigate(`/appointments/${docsToBeCompleted[index + 1].id}`, {
				state: { appointmentId: docsToBeCompleted[index + 1].id },
			});
		}
	};

	//if the user gets to a page that isn't an appointment page, stop completing
	useEffect(() => {
		if (!completing) return;
		const url = pathname.split("/");
		const isOnIndividualAppointmentPage = url[1] === "appointments";
		if (!isOnIndividualAppointmentPage) {
			setCompleting(false);
		}
	}, [pathname]);

	if (isLoadingData) {
		return <></>;
	}

	if (completing) {
		return (
			<div className="flex flex-col items-center justify-center">
				<div className="flex items-start justify-between w-full">
					<button
						type="button"
						className={`rounded flex items-center bg-white px-2 py-0.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer ${currentIndex < 1 ? "opacity-50 cursor-not-allowed text-gray-500" : ""
							}`}
						disabled={currentIndex < 1}
						onClick={() => {
							handleBack(currentIndex);
						}}
					>
						<ChevronLeftIcon className="-ml-1 h-3 w-3" aria-hidden="true" />
						Previous
					</button>

					<div className="text-xs font-medium flex flex-col items-center justify-center">
						{currentIndex + 1} of {docsToBeCompleted?.length}
						<button
							className="flex items-centerbg-white px-2 py-0.5 text-xs font-semibold text-gray-900 hover:bg-gray-50"
							onClick={() => {
								handleCancel();
							}}
						>
							Cancel
						</button>
					</div>
					<button
						type="button"
						className="flex items-center rounded bg-white px-2 py-0.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onClick={() => {
							markAsComplete(currentIndex);
						}}
					>
						{currentIndex === docsToBeCompleted.length - 1 ? "Finish" : "Next"}
						<ChevronRightIcon className="-mr-1 h-3 w-3" aria-hidden="true" />
					</button>
				</div>

				<div className="w-full h-2 py-0 my-0.5 bg-gray-200 rounded-full">
					<div
						className="bg-max-700  h-2 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
						style={{ width: `${Math.floor((currentIndex / docsToBeCompleted?.length) * 100)}%` }}
					></div>
				</div>
			</div>
		);
	}

	return (
		<div className="inline-flex rounded-md shadow-sm">
			{docsToBeCompleted?.length > 0 ? (
				<div className="hidden sm:flex flex-row items-center gap-x-2"></div>
			) : (
				<></>
				// <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">All Documents Completed!</span>
			)}
		</div>
	);
};
export default CompleteDocuments;
