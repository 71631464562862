import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import UserIcon from "@icons/user-01.svg?react";
import { Fragment, useState } from "react";
import UploadIcon from "@icons/upload-01.svg?react";
import SettingsIcon from "@icons/settings-02.svg?react";
import HelpCircleIcon from '@icons/help-circle.svg?react';
import LogOutIcon from "@icons/log-out-02.svg?react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { supabase } from "@/common/lib/supabaseClient";
import { clearDoctorState } from "@/state/redux/doctorSlice";
import { clearDocumentState } from "@/state/redux/documentSlice";
import * as Sentry from "@sentry/react";
import UploadModal from "@/common/components/reupload-modal/ReuploadModal";
import { RecordingState } from "@/common/utils/types";
import moment from "moment";
import { useAccountContext } from "@/state/providers/AccountProvider";
import { VITE_ONBOARDING_HELP_URL, VITE_PUBLIC_ENV } from "@/common/utils/constants";
import RouteIcon from "@icons/route.svg?react";
import CursorClickIcon from "@icons/cursor-click-02.svg?react";
import CalendarCheckIcon from "@icons/calendar-check-02.svg?react";
import classNames from "classnames";

/**
 * Profile menu component
 * @returns React.FC
 * @example <ProfileMenu />
 */
const ProfileMenu: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { accountData } = useAccountContext();

    const incompleteUploadsCount = useAppSelector((state) => state.patientTableRecords.incompleteUploadsCount);
    const { globalRecordingState } = useAppSelector((state) => state.globalState);

    const [openModal, setOpenModal] = useState(false);

    /**
     * Sign out the user
     * @param {Event} e - The event object
     * @returns {Promise<void>}
     * */
    const signOut = async (e: { preventDefault: () => void }) => {
        try {
            e.preventDefault();
            const { error } = await supabase.auth.signOut({ scope: "local" });
            dispatch(clearDoctorState());
            dispatch(clearDocumentState());
            if (error) {
                Sentry.captureException(error);
                // Clearing specific local storage key based on a pattern. In case auth breaks down.
                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i);
                    if (key && key.match(/^sb-.*-auth-token$/)) {
                        localStorage.removeItem(key);
                        window.location.reload();
                    }
                }
            } else {
                navigate("/");
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    /**
     * Open a link in a new tab
     * @param {string} link - The link to open
     * @param {boolean} openInNewTab - Whether to open in a new tab
     * @returns {void}
     * */
    function openLink(link: string, openInNewTab: boolean = false) {
        if (openInNewTab) {
            window.open(link, "_blank", "noopener,noreferrer");
        } else {
            window.open(link, "_self");
        }
    }

    /**
     * Check if the given time is more than 30 days after the current time
     * @param {string} givenTimeStr - The given time string
     * @returns {boolean
     * */
    function checkIfMoreThan30DaysAfter(givenTimeStr: string | null | undefined) {
        if (!givenTimeStr) return false;
        try {
            const givenTime = moment(givenTimeStr); // 'YYYY-MM-DDTHH:MM:SSZ' format
            const currentTime = moment();

            const diffDays = currentTime.diff(givenTime, "days");

            return diffDays < 30;
        } catch (error) {
            return false;
        }
    }

    const isRecording = globalRecordingState === RecordingState.recording || globalRecordingState === RecordingState.paused;

    return (
        <Fragment>
            <Menu as="div" className="relative inline-block text-left">
                <div className="relative ">
                    <MenuButton disabled={isRecording} className={"focused"}>
                        <div className={classNames(isRecording ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer", "flex items-center justify-center p-2 bg-max-25 rounded-lg border border-solid border-gray-200 max-h-[36px] max-w-[36px] cursor-pointer relative  ")}>
                            <UserIcon className="w-5 h-5 text-color-600 bg-max-25" />
                            {incompleteUploadsCount > 0 && (
                                <div style={{
                                    top: "-4px",
                                    right: "-4px"
                                }}
                                    className="absolute  h-2 w-2 bg-red-600 rounded-full" />
                            )}
                        </div>
                    </MenuButton>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <MenuItem>
                                {({ focus }) => (
                                    <button
                                        disabled={isRecording}
                                        onClick={() => setOpenModal(true)}
                                        className={`${focus ? 'bg-max-700 text-white' : incompleteUploadsCount > 0 ? "text-red-500 " : 'text-gray-700'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        <UploadIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : incompleteUploadsCount > 0 ? 'text-red-500' : 'text-gray-700'}`} aria-hidden="true" />
                                        Incomplete Uploads
                                    </button>
                                )}
                            </MenuItem>
                            <MenuItem>
                                {({ focus }) => (
                                    <button
                                        disabled={isRecording}
                                        onClick={() => navigate("/settings")}
                                        className={`${focus ? 'bg-max-700 text-white' : 'text-gray-700'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        <SettingsIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : 'text-gray-700'}`} aria-hidden="true" />
                                        Settings
                                    </button>
                                )}
                            </MenuItem>
                            <MenuItem>
                                {({ focus }) => (
                                    <button id={isRecording ? "" : "IntercomCustomLink"}
                                        name="Help Center"
                                        onClick={() => openLink("https://help.gohappydoc.com/en/", true)}
                                        className={`${focus ? 'bg-max-700 text-white' : 'text-gray-700'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                        <HelpCircleIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : 'text-gray-700'}`} aria-hidden="true" />
                                        Help Center
                                    </button>
                                )}
                            </MenuItem>
                        </div>
                        {checkIfMoreThan30DaysAfter(accountData?.created_at) && (
                            <div className="px-1 py-1 ">
                                <MenuItem>
                                    {({ focus }) => (
                                        <button
                                            disabled={isRecording}
                                            onClick={() => {
                                                const link = "https://help.gohappydoc.com/en/articles/8562620-getting-started-with-happydoc";
                                                openLink(link, true);
                                            }}
                                            className={`${focus ? 'bg-max-700 text-white' : 'text-gray-700'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            <CursorClickIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : 'text-gray-700'}`} aria-hidden="true" />
                                            Getting Started?
                                        </button>
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    {({ focus }) => (
                                        <button
                                            disabled={isRecording}
                                            onClick={() => {
                                                let link =
                                                    VITE_PUBLIC_ENV === "production"
                                                        ? "https://app.gohappydoc.com/?product_tour_id=570626"
                                                        : VITE_PUBLIC_ENV === "development"
                                                            ? "https://happy-doc-3-0-git-develop-happydoc-s-team.vercel.app/?product_tour_id=570626"
                                                            : "";
                                                if (!link) return;
                                                openLink(link);
                                            }}
                                            className={`${focus ? 'bg-max-700 text-white' : 'text-gray-700'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            <RouteIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : 'text-gray-700'}`} aria-hidden="true" />
                                            Product Tour
                                        </button>
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    {({ focus }) => (
                                        <button id={isRecording ? "" : "IntercomCustomLink"}
                                            name="Help Center"
                                            onClick={() => {
                                                let link = VITE_ONBOARDING_HELP_URL || "https://calendly.com/d/cpwt-93k-xvy/onboarding";
                                                if (!link) return;
                                                openLink(link, true);
                                            }}
                                            className={`${focus ? 'bg-max-700 text-white' : 'text-gray-700'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                            <CalendarCheckIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : 'text-gray-700'}`} aria-hidden="true" />
                                            Schedule Onboarding
                                        </button>
                                    )}
                                </MenuItem>
                            </div>
                        )}
                        <div className="px-1 py-1">
                            <MenuItem>
                                {({ focus }) => (
                                    <button
                                        disabled={isRecording}
                                        onClick={signOut}
                                        className={`${focus ? 'bg-max-700 text-white' : 'text-gray-700'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        <LogOutIcon className={`mr-2 h-4 w-4 ${focus ? 'text-white' : 'text-gray-700'}`} aria-hidden="true" />
                                        Sign Out
                                    </button>
                                )}
                            </MenuItem>
                        </div>
                    </MenuItems>
                </Transition>
            </Menu>
            <UploadModal
                disabled={isRecording}
                setOpen={setOpenModal}
                open={openModal}
                showButton={false}
            />
        </Fragment>
    );
};

export default ProfileMenu;