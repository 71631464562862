import { RootState } from '@/app/store';
import { RecordingState } from '@/common/utils/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalState {
    completing: boolean;
    completionId: string | null;
    globalRecordingState: RecordingState;
    isUploadingGlobal: boolean;
}

const initialState: GlobalState = {
    completing: false,
    completionId: null,
    globalRecordingState: RecordingState.inactive,
    isUploadingGlobal: false,
};

export const isDisabled = (state: GlobalState): boolean => {
    return state.isUploadingGlobal ||
        state.globalRecordingState === RecordingState.recording ||
        state.globalRecordingState === RecordingState.paused;
};
export const selectGlobalState = (state: RootState): GlobalState => state.globalState;

export const selectIsDisabled = createSelector(
    [selectGlobalState],
    (globalState) => isDisabled(globalState)
);

const globalStateSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        setCompleting(state, action: PayloadAction<boolean>) {
            state.completing = action.payload;
        },
        setCompletionId(state, action: PayloadAction<string | null>) {
            state.completionId = action.payload;
        },
        setGlobalRecordingState(state, action: PayloadAction<RecordingState>) {
            state.globalRecordingState = action.payload;
        },
        setIsUploadingGlobal(state, action: PayloadAction<boolean>) {
            state.isUploadingGlobal = action.payload;
        },
    },
});

export const {
    setCompleting,
    setCompletionId,
    setGlobalRecordingState,
    setIsUploadingGlobal,
} = globalStateSlice.actions;

export default globalStateSlice.reducer;
