import { createSlice, PayloadAction, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { AddPatientRequest, Doctor, PatientRecord, PatientStage, TranscriptSectionStatus } from "@common/utils/types";
import { fetchPatientRecords, upsertPatientRecord, deletePatientRecords, fetchPatientRecord } from "@common/lib/supabaseClient";
import { getWeekRange, isSameDay, isSameWeek, transformTableRecordToPatientRecord } from "@common/utils/helpers";
import { RootState } from "@/app/store";
import { addPatient } from "@/common/lib/api";
import * as Sentry from "@sentry/react"
import { toast } from "react-toastify";
/*
NOTES:

(1) Redux toolkit allows us to write "mutating" logic in reducers. 
It uses Immer inside to allow us to write simpler immutable updates.
 In all other places, direct object/array mutations are not allowed.
*/

export type SortedColumn = {
	column: "scheduled_at" | "patient_name" | string;
	direction: "asc" | "desc";
};

export type status = "idle" | "loading" | "succeeded" | "failed";

// utility function to filter records by doctor
const isRecordForSelectedDoctor = (record: PatientRecord, selectedDoctor: Doctor | null) => {
	return selectedDoctor ? record.doctors?.id === selectedDoctor.id || record.doctor_id === selectedDoctor.id : true;
}

const initialState: {
	tableRecords: PatientRecord[];
	status: status;
	error: string | null;
	sortedColumn: SortedColumn;
	currentStage: PatientStage;
	// may change to uuid
	selectedTableRecords: PatientRecord[];
	incompleteUploadsCount: number
	pollingLocalDB: boolean;
	view: 'day' | 'week';
} = {
	tableRecords: [],
	status: "idle",
	error: null,
	sortedColumn: {
		column: "scheduled_at",
		direction: "asc",
	},
	currentStage: PatientStage.Ongoing,
	selectedTableRecords: [],
	incompleteUploadsCount: 0,
	pollingLocalDB: false,
	view: 'day',
};

export const patientTableRecordsSlice = createSlice({
	name: "patientTableRecords",
	initialState,
	reducers: {
		addRecord: (state, action: PayloadAction<PatientRecord>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (!record) {
				state.tableRecords.push(action.payload);
			}
		},
		removeRecord: (state, action: PayloadAction<{ id: string }>) => {
			state.tableRecords = state.tableRecords.filter((record) => record.id !== action.payload.id);
		},
		removeRecords: (state, action: PayloadAction<{ ids: string[] }>) => {
			state.tableRecords = state.tableRecords.filter((record) => !action.payload.ids.includes(record?.id));
		},
		incrementDocumentCount: (state, action: PayloadAction<{ id: string }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record) {
				if (!record?.chat_completions || !record?.chat_completions[0]?.count) {
					record.chat_completions = [{ count: 1 }];
				} else {
					record.chat_completions[0].count++;
				}
			}
		},
		decrementDocumentCount: (state, action: PayloadAction<{ id: string | null | undefined }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record) {
				if (record?.chat_completions && record?.chat_completions[0]?.count) {
					record.chat_completions[0].count--;
				}
			}
		},
		incrementRecordingCount: (
			state,
			action: PayloadAction<{ transcriptionId: string; sectionId: string; status: TranscriptSectionStatus | null }>,
		) => {
			const record = state.tableRecords.find((record) => record.transcriptions?.id === action.payload.transcriptionId);
			if (record && record.transcriptions?.transcript_sections) {
				let found = false;
				//If the section is already in the list, update the error
				let updatedTranscriptSections = record.transcriptions.transcript_sections.map((section) => {
					if (section.id === action.payload.sectionId) {
						found = true;
						return { ...section, status: action.payload.status };
					}
					return section;
				});
				//If the section is not in the list, add it
				if (!found) {
					updatedTranscriptSections.push({ id: action.payload.sectionId, status: action.payload.status });
				}
				record.transcriptions.transcript_sections = updatedTranscriptSections;
			}
		},
		decrementRecordingCount: (state, action: PayloadAction<{ transcriptionId: string; sectionIds: string[] }>) => {
			const record = state.tableRecords.find((record) => record.transcriptions?.id === action.payload.transcriptionId);
			if (record && record.transcriptions?.transcript_sections) {
				const sectionIdsSet = new Set(action.payload.sectionIds);
				const filteredSections = record.transcriptions.transcript_sections.filter(
					(section) => !sectionIdsSet.has(section.id),
				);
				record.transcriptions.transcript_sections = filteredSections;
			}
		},
		updateTableRecordStage: (state, action: PayloadAction<{ id: string; newStage: PatientStage }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record) {
				record.stage = action.payload.newStage;
			}
		},
		//WARNING: This dangerous and can lead to overwriting un nested data like transcriptions or chat completions. Only pass full values here.
		updateTableRecord: (state, action: PayloadAction<{ id: string; updatedRecord: PatientRecord }>) => {
			let recordIndex = state.tableRecords.findIndex((record) => record.id === action.payload.id)
			if (recordIndex !== -1) {
				state.tableRecords[recordIndex] = { ...state.tableRecords[recordIndex], ...action.payload.updatedRecord };
			}
		},
		//WARNING: This dangerous and can lead to overwriting un nested data like transcriptions or chat completions. Only pass full values here.
		addOrUpdateRecord: (state, action: PayloadAction<PatientRecord>) => {
			let record = state.tableRecords.findIndex((record) => record.id === action.payload.id);

			// If the record already exists, update it
			if (record !== -1) {
				state.tableRecords[record] = { ...state.tableRecords[record], ...action.payload };
				// If the record doesn't exist, add it
			} else {
				state.tableRecords.push(action.payload);
			}
		},
		addPatientRecord: (state, action: PayloadAction<PatientRecord>) => {
			// check if the record already exists
			const record = state.tableRecords.find((record) => record.id === action.payload.id);

			// if the record doesn't exist, and it's scheduled for today and belongs to the correct doctor add it where it belongs in the table based of scheduled_at ASC. 
			if (!record) {
				state.tableRecords.push(action.payload);
			}
		},
		updateTranscriptSectionStatus: (state, action: PayloadAction<{ id: string; sectionId: string; status: TranscriptSectionStatus }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record && record.transcriptions?.transcript_sections) {
				let updatedTranscriptSections = record.transcriptions.transcript_sections.map((section) => {
					if (section.id === action.payload.sectionId) {
						return { ...section, status: action.payload.status };
					}
					return section;
				}
				);
				record.transcriptions.transcript_sections = updatedTranscriptSections;
			}
		},
		setSortedColumn: (state, action: PayloadAction<SortedColumn>) => {
			state.sortedColumn = action.payload;
		},
		changeCurrentStage: (state, action: PayloadAction<PatientStage>) => {
			state.currentStage = action.payload;
		},
		addSelectedTableRecord: (state, action: PayloadAction<PatientRecord>) => {
			const record = state.selectedTableRecords.find((record) => record.id === action.payload.id);
			if (!record) state.selectedTableRecords.push(action.payload);
		},
		removeSelectedTableRecord: (state, action: PayloadAction<{ id: string }>) => {
			state.selectedTableRecords = state.selectedTableRecords.filter((record) => record.id !== action.payload.id);
		},
		resetSelectedTableRecords: (state) => {
			state.selectedTableRecords = [];
		},
		addSelectedTableRecords: (state, action: PayloadAction<PatientRecord[]>) => {
			state.selectedTableRecords = action.payload;
		},
		setStatus: (state, action: PayloadAction<status>) => {
			state.status = action.payload;
		},
		setIncompleteUploadCount: (state, action) => {
			state.incompleteUploadsCount = action.payload;
		},
		startPollingLocalDB: (state) => {
			state.pollingLocalDB = true;
		},
		stopPollingLocalDB: (state) => {
			state.pollingLocalDB = false;
		},
		setView: (state, action: PayloadAction<'day' | 'week'>) => {
			state.view = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPatientRecordsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchPatientRecordsAsync.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.tableRecords = action.payload as PatientRecord[];
			})
			.addCase(fetchPatientRecordsAsync.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
	},
});

//Export Async Thunks
interface FetchPatientRecordsArgs {
	selectedDate: any;
	doctorId: string;
	abortSignal: AbortSignal;
}

export const fetchPatientRecordsAsync = createAsyncThunk(
	"patientRecords/fetchPatientRecordsAsync",
	async ({ selectedDate, doctorId, abortSignal }: FetchPatientRecordsArgs, { getState }) => {
		const state = getState() as RootState;
		const { view } = state.patientTableRecords;

		if (view === 'day') {
			// fetch all appointments for the day
			const response = await fetchPatientRecords(selectedDate, doctorId, abortSignal);
			return response.data;
		} else {
			// fetch all appointments for the week
			const weekRange = getWeekRange(selectedDate);

			const response = await fetchPatientRecords(weekRange, doctorId, abortSignal);
			return response.data;
		}
	},
);

interface InsertPatientRecordArgs {
	newPatientRecord: AddPatientRequest;
	addToTable: boolean;
}

export const insertPatientRecord = createAsyncThunk(
	"patientRecords/insertPatientRecord",
	async ({ newPatientRecord, addToTable = true }: InsertPatientRecordArgs, { dispatch }) => {

		const { data, error } = await addPatient(newPatientRecord)

		if (error) {
			toast.error("Failed to insert patient. Please try again.")
			Sentry.captureException(error)
			return null
		}

		// add the record to the table
		if (addToTable && data) {
			dispatch(addRecord(data));
		}

		return data
	},
);
interface UpdatePatientRecordArgs {
	updatedTableRecord: PatientRecord;
	updateTable: boolean;
}

export const updatePatientRecord = createAsyncThunk(
	"patientRecords/updatePatientRecord",
	async ({ updatedTableRecord, updateTable }: UpdatePatientRecordArgs, thunkAPI) => {
		let updated = transformTableRecordToPatientRecord(updatedTableRecord);
		const pr = await upsertPatientRecord(updated);
		if (updateTable) {
			// update the record in the table
			thunkAPI.dispatch(updateTableRecord({ id: updatedTableRecord.id, updatedRecord: updatedTableRecord }))

		} else {
			//Remove it, update value different from filters
			thunkAPI.dispatch(removeRecord({ id: updatedTableRecord.id }));
		}

		return pr
	},
);

export const deletePatientRecordsAsync = createAsyncThunk(
	"patientRecords/deletePatientRecordsAsync",
	async (ids: string[], thunkAPI) => {
		thunkAPI.dispatch(removeRecords({ ids }));
		await deletePatientRecords(ids);
	},
);

// This thunk is used to handle the patient event broadcasted from realtime
export const handlePatientTableBroadcast = createAsyncThunk(
	"patientRecords/handlePatientTableBroadcast",
	async ({ patientRecordId }: { patientRecordId: string }, { dispatch, getState }) => {
		const state = getState() as RootState;
		const { selectedDoctor } = state.doctors;
		const { tableRecords, view } = state.patientTableRecords;

		// get the record from the db
		const { data: patientRecord, error } = await fetchPatientRecord(patientRecordId);

		if (error || !patientRecord) {
			return null;
		}

		// check if the record already exists
		const tableRecord = tableRecords.find((record) => record.id === patientRecord.id);

		// check if the record is scheduled for today or this week depending on view
		const isScheduledToday = view === 'day' ?
			isSameDay(new Date(patientRecord?.scheduled_at || ""), new Date()) :
			isSameWeek(new Date(patientRecord?.scheduled_at || ""), new Date());

		// check if the record belongs to the correct doctor or if no doctor is selected
		const isCorrectDoctor = isRecordForSelectedDoctor(patientRecord, selectedDoctor);

		// if patient event doesn't exist, add it to the table
		if (!tableRecord && isScheduledToday && isCorrectDoctor) {
			dispatch(addRecord(patientRecord));
		} else if (tableRecord) {
			// if the record exists in the table, update it
			dispatch(updateTableRecord({ id: patientRecord.id, updatedRecord: patientRecord }));
		}

		return patientRecord;
	},
);

//Export the actions (add these here to call them into thunks)
export const {
	addRecord,
	removeRecord,
	addOrUpdateRecord,
	removeRecords,
	incrementDocumentCount,
	updateTableRecord,
	setSortedColumn,
	changeCurrentStage,
	updateTableRecordStage,
	incrementRecordingCount,
	addSelectedTableRecord,
	removeSelectedTableRecord,
	resetSelectedTableRecords,
	addSelectedTableRecords,
	decrementRecordingCount,
	setStatus,
	decrementDocumentCount,
	updateTranscriptSectionStatus,
	setIncompleteUploadCount,
	startPollingLocalDB,
	stopPollingLocalDB,
	setView
} = patientTableRecordsSlice.actions;

//Export Selectors
export const selectAllTableRecords = (state: { patientTableRecords: { tableRecords: PatientRecord[] } }) =>
	state.patientTableRecords.tableRecords;

export const selectTableRecordStatus = (state: { patientTableRecords: { status: status } }) =>
	state.patientTableRecords.status;

export const selectSortedColumn = (state: { patientTableRecords: { sortedColumn: SortedColumn } }) =>
	state.patientTableRecords.sortedColumn;

export const selectCurrentStage = (state: { patientTableRecords: { currentStage: PatientStage } }) =>
	state.patientTableRecords.currentStage;

export const selectPatientRecordById = (state: RootState, patientRecordId: string) =>
	state.patientTableRecords.tableRecords.find((record) => record.id === patientRecordId);

export const doesPatientRecordExist = (state: RootState, patientRecordId: string) =>
	state.patientTableRecords.tableRecords.some((record) => record.id === patientRecordId);

export const selectSortedTableRecordsByStage = createSelector(
	[
		selectAllTableRecords,
		(state: RootState) => state.patientTableRecords.sortedColumn,
		(state: RootState) => state.patientTableRecords.currentStage,
		(state: RootState) => state.doctors.selectedDoctor,
	],
	(tableRecords, sortedColumn, currentStage, selectedDoctor) => {
		return tableRecords
			.filter((record) => record.stage === currentStage && isRecordForSelectedDoctor(record, selectedDoctor))
			.sort((a, b) => {
				let valA = a[sortedColumn.column as keyof PatientRecord];
				let valB = b[sortedColumn.column as keyof PatientRecord];

				if (sortedColumn.column === "patient_name") {
					// Convert patient names to lowercase for case-insensitive sorting
					valA = typeof valA === "string" ? valA.toLowerCase() : "";
					valB = typeof valB === "string" ? valB.toLowerCase() : "";
				}
				if (!valA || !valB) return 0;
				return (valA < valB ? -1 : 1) * (sortedColumn.direction === "asc" ? 1 : -1);
			});
	},
);

export const selectTableRecordById = createSelector(
	[selectAllTableRecords, (state, id: string) => id],
	(tableRecords, id) => {
		const record = tableRecords.find((record) => record.id === id);
		return record ? record : null;
	},
);

export const selectOngoingRecordCount = createSelector([selectAllTableRecords, (state: RootState) => state.doctors.selectedDoctor], (tableRecords, selectedDoctor) => {
	return tableRecords.filter((record) => record.stage === PatientStage.Ongoing && isRecordForSelectedDoctor(record, selectedDoctor)).length;
});

export const selectReviewRecordCount = createSelector([selectAllTableRecords, (state: RootState) => state.doctors.selectedDoctor], (tableRecords, selectedDoctor) => {
	return tableRecords.filter((record) => record.stage === PatientStage.Review && isRecordForSelectedDoctor(record, selectedDoctor)).length;
});

export const selectFinalizedRecordCount = createSelector([selectAllTableRecords, (state: RootState) => state.doctors.selectedDoctor], (tableRecords, selectedDoctor) => {
	return tableRecords.filter((record) => record.stage === PatientStage.Finalized && isRecordForSelectedDoctor(record, selectedDoctor)).length;
});

//Export Reducer
export default patientTableRecordsSlice.reducer;
