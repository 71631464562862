import { RecordingWidgetState, useAudioRecordingProvider } from '@/state/providers/RecordingProvider';
import classNames from 'classnames';
import WidgetDocumentSelect from './WidgetDocumentSelect';
import { useAppSelector } from '@/common/hooks/useRedux';

// internal icons
import FilePlusIcon from "@icons/file-plus-02.svg?react";
import MicrophoneIcon from "@icons/microphone-02.svg?react";
import Square from "@icons/square.svg?react";
import PlusCircleIcon from "@icons/plus-circle.svg?react";
import SaveIcon from "@icons/save-01.svg?react";
import SoundWaves1 from "@icons/recording-01.svg?react";
import SoundWaves2 from "@icons/recording-02.svg?react";


interface Props {
    pulseScale: number;
    audioTime: string;
}

// view for when widget is in the "recording" state (during recording)
const RecordingView: React.FC<Props> = ({ pulseScale, audioTime }) => {
    const {
        // audio recording controls
        recorderControls: {
            togglePauseResume,
        },
        // recording widget controls
        recordingWidgetControls: {
            recordingWidgetState,
            setRecordingWidgetState,
            handleFinishRecording,
        },
        // patient data controls
        recordingPatientControls: {
            patientName,
        },
        // document select controls
        recordingDocumentSelectControls: {
            setGenerateOnStop,
        },
    } = useAudioRecordingProvider();

    const { selectedDocuments } = useAppSelector(state => state.documents);

    const isPaused = recordingWidgetState === RecordingWidgetState.PAUSED;

    /**
    * Save and Close
    * handler for the save and close button in the recording view
    */
    const handleSaveAndClose = async (e: React.MouseEvent<HTMLButtonElement>) => {

        // lock mouse events
        e.preventDefault();

        // set widget state back to uploading
        setRecordingWidgetState(RecordingWidgetState.UPLOADING);

        // set generate on stop to false
        setGenerateOnStop(false);

        // save recording
        handleFinishRecording();
    }

    /**
   * Generate Documents
   * handler for the generate button in the recording view
  */
    const handleGenerate = (e: React.MouseEvent<HTMLButtonElement>) => {

        // lock mouse events
        e.preventDefault();

        // set widget state back to uploading
        setRecordingWidgetState(RecordingWidgetState.UPLOADING);

        // set generate on stop to true
        setGenerateOnStop(true);

        // save recording
        handleFinishRecording();
    }


    return (
        <div className="w-full h-full">
            <div className="w-full flex flex-col items-center">
                <div className="mt-9 w-full">
                    <div className="flex flex-col items-center">
                        <button
                            id={isPaused ? "recording-widget-pause-button" : "recording-widget-stop-button"}
                            type="button"
                            className={classNames(
                                "relative w-[90px] h-[90px] rounded-full flex flex-col items-center justify-center text-xs font-semibold shadow-sm cursor-pointer transition-transform",
                                isPaused
                                    ? "bg-max-700 hover:bg-max-800 text-white group-hover:bg-max-800 group"
                                    : "bg-red-500 text-white hover:bg-red-600",
                            )}
                            style={{
                                boxShadow: isPaused
                                    ? "0 0 0 24px #2A3648"
                                    : `0 0 0 ${8 + (pulseScale > 0.25 ? 0.25 : pulseScale) * 12 * 8}px #2A3648`,
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                togglePauseResume();
                            }}
                        >
                            {isPaused ? (
                                <>
                                    <div>
                                        <MicrophoneIcon className="h-12 w-12 text-white" />
                                    </div>
                                    <div className="w-8 h-8 absolute top-0 right-0 text-white bg-max-700 rounded-full justify-center items-center inline-flex group-hover:bg-max-800">
                                        <PlusCircleIcon className="w-6 h-6" />
                                    </div>
                                </>
                            ) : (
                                <Square className="w-12 h-12 text-white mb-1" />
                            )}
                        </button>
                        <div className="bg-shadow-800 mt-8 rounded-lg">
                            <div className="px-4 py-2 flex flex-row items-center text-white">
                                <div className="flex flex-row">
                                    <SoundWaves1 className="w-6 h-6" />
                                    <SoundWaves2 className="w-6 h-6" />
                                    <SoundWaves1 className="w-6 h-6" />
                                </div>
                                <div className="pl-2 text-center text-sm font-medium leading-tight">
                                    {audioTime}
                                </div>
                            </div>
                        </div>
                        <div className="text-center flex flex-row items-center h-[70px] text-white text-sm font-medium leading-tight">
                            {isPaused
                                ? (
                                    <>
                                        Continue recording or <br /> generate selected documents
                                    </>
                                )
                                :
                                patientName ? (
                                    <>
                                        Recording a Clip for {patientName}
                                    </>
                                ) : (
                                    <>
                                        Recording a Clip
                                    </>
                                )

                            }
                        </div>

                    </div>
                </div>
            </div>
            <div className="w-full justify-left">
                <WidgetDocumentSelect />
                <div>
                    <div className="w-full h-[76px] bg-shadow-800 flex flex-row items-center">
                        <button
                            className="w-full h-9 ml-6 mr-2.5 bg-white border-max-300 text-max-800 rounded-lg shadow border justify-center items-center inline-flex text-xs sm:text-sm font-semibold leading-tight"
                            onClick={handleSaveAndClose}
                        >
                            <SaveIcon className="w-4 h-4 sm:w-5 sm:h-5 relative mr-1" />
                            {isPaused ? (
                                <>
                                    Save & Close
                                </>
                            ) : (
                                <>
                                    Stop & Close
                                </>
                            )}
                        </button>
                        <button
                            disabled={selectedDocuments.length === 0}
                            className={classNames(
                                "w-full h-9 ml-2.5 mr-6 rounded-lg shadow border justify-center items-center inline-flex text-xs sm:text-sm font-semibold leading-tight",
                                selectedDocuments.length === 0
                                    ? "border-shadow-300 text-shadow-300 bg-shadow-700"
                                    : "border-max-700 text-white bg-max-700"
                            )}
                            onClick={handleGenerate}
                        >
                            <FilePlusIcon className="w-4 h-4 sm:w-4 sm:h-4 relative mr-1" />
                            {isPaused ? (
                                <>
                                    Generate
                                </>
                            ) : (
                                <>
                                    Stop & Generate
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecordingView;