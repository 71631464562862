import React, { useState } from 'react';
import { RecordingWidgetState, useAudioRecordingProvider } from '@/state/providers/RecordingProvider';
import MicrophoneIcon from "@icons/microphone-02.svg?react";

// view for when widget is in the "waiting" state (before recording)
const WaitingView: React.FC = () => {
    /**
       * Audio Recording Provider
       * hook for managing widget state, audio recording, file upload, document select, patient data
      **/
    const {
        // audio recording controls
        recorderControls: {
            recordingState,
        },
        // recording widget controls
        recordingWidgetControls: {
            setRecordingWidgetState,
            handleStartRecording,
        },
    } = useAudioRecordingProvider();

    const [patientName, setPatientName] = useState<string>("");

    return (
        <div className="w-full h-full bg-shadow-900 rounded-lg shadow flex items-center gap-2 sm:gap-4 px-2 sm:px-4 py-3">
            <div>
                <div className="w-[80px] h-[80px] flex justify-center items-center bg-max-700 hover:bg-max-800 focus-visible:outline-bg-max-700 rounded-full border-8 border-shadow-800">
                    <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        setRecordingWidgetState(RecordingWidgetState.RECORDING);

                        e.preventDefault();
                        handleStartRecording({ patientName });
                    }}>
                        <MicrophoneIcon className="w-9 h-9" />
                    </button>
                </div>
            </div>

            {/* right column */}
            <div className="flex flex-col gap-2 ml-0 sm:ml-1 mr-4 w-full">
                <div className="text-white text-xs sm:text-sm font-medium font-['Plus Jakarta Sans'] leading-tight">
                    Start recording a Clip
                </div>

                {/* patient name input */}
                <div id="recording-widget-patient-name" className="mt-0.5">
                    <input
                        id="recording-widget-patient-name"
                        type="text"
                        name="patient-name"
                        placeholder="Enter patient name"
                        value={patientName}
                        onChange={(e) => {
                            setPatientName(e.target.value);
                        }}
                        disabled={recordingState === "recording" || recordingState === "paused"}
                        className="block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-xs leading-tight sm:text-sm sm:leading-6 text-gray-900 focus:ring-max-700"
                    />
                </div>

                {/* multi patient is currently not supported */}
                <div className="flex items-center h-5">
                    {/* <input
                        type="checkbox"
                        id="multi-patient"
                        className="text-max-500 focus:border-max-500 focus:ring-0 rounded border-gray-300"
                    />
                    <label htmlFor="multi-patient" className="ml-2 text-white text-xs sm:text-sm font-medium">
                        Multi-patient visit?
                    </label> */}
                </div>

            </div>
        </div >
    );
};

export default WaitingView;