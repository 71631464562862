function UploadProgressBar({ percentage, displayPercentage = true }: { percentage: number, displayPercentage?: boolean }) {
	return (
		<div className="w-full bg-gray-200 rounded-full h-full">
			<div
				className="bg-max-700  text-xs font-medium text-white text-center p-0.5 leading-none rounded-full h-full"
				style={{ width: `${percentage}%` }}
			>
				{displayPercentage && percentage + "%"}
			</div>
		</div>
	);
}

export default UploadProgressBar;
