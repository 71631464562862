// external dependencies
import React, { createContext, useContext, useState, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";

// internal data providers, hooks, and state
import useAudioRecorder, { recorderControls, isRecordingError } from "@common/hooks/useAudioRecorder";
import useFileUpload from "@common/hooks/useFileUpload";
import usePatientState from "@/features/visits/list-view/state/usePatientState";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";

// internal components and types
import { PatientRecord, RecordingState } from "@common/utils/types";
import { toast } from "react-toastify";
import { fetchPatientRecord } from "@/common/lib/supabaseClient";

/**
 * Context and Types
**/
// recording widget states
export enum RecordingWidgetState {
	WAITING,
	RECORDING,
	PAUSED,
	UPLOADING,
	SAVED,
}

export enum RecordingWidgetUploadState {
	UPLOADING,
	SUCCESS,
	WARNING,
	ERROR,
}

interface HandleStartRecordingParams {
	patientRecordId?: string | null;
	patientName?: string | null;
}

// controls recorder hook
export type recordingWidgetControls = {
	recordingWidgetState: RecordingWidgetState,
	setRecordingWidgetState: (state: RecordingWidgetState) => void,
	handleStartRecording: (params: HandleStartRecordingParams) => void,
	handleFinishRecording: () => void,
	renderWidget: boolean,
	setRenderWidget: (render: boolean) => void,
}

// controls patient data
export type recordingPatientControls = {
	patientName: string,
	setPatientName: (name: string) => void,
	patientRecordId: string,
	setPatientRecordId: (id: string) => void,
}

// controls file upload
export type recordingFileUploadControls = {
	uploadProgress: number,
	isUploading: boolean,
	recordingWidgetUploadState: RecordingWidgetUploadState,
}

// controls document selection
export type recordingDocumentSelectControls = {
	setGenerateOnStop: (generate: boolean) => void,
}

// recording provider context
interface AudioRecorderContextValue {
	recorderControls: recorderControls;
	recordingWidgetControls: recordingWidgetControls;
	recordingPatientControls: recordingPatientControls;
	recordingFileUploadControls: recordingFileUploadControls;
	recordingDocumentSelectControls: recordingDocumentSelectControls;
}

const AudioRecorderContext = createContext<AudioRecorderContextValue | undefined>(undefined);

export function useAudioRecordingProvider(): AudioRecorderContextValue {
	const context = useContext(AudioRecorderContext);
	if (!context) {
		throw new Error("useAudioRecorderContext must be used within a AudioRecorderProvider");
	}
	return context;
}

export function AudioRecorderProvider({ children }: { children: React.ReactNode }) {

	/**
	 * recording provider state
	**/
	const [recordingWidgetState, setRecordingWidgetState] = useState<RecordingWidgetState>(RecordingWidgetState.WAITING);
	const [renderWidget, setRenderWidget] = useState<boolean>(false);
	const [patientRecordId, setPatientRecordId] = useState<string>("");

	/**
	 * Providers and Hooks
	**/
	// AUDIO RECORDER
	const {
		startRecording,
		stopRecording,
		togglePauseResume,
		recordingState,
		setRecordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		resetRecorder
	} = useAudioRecorder();

	// PATIENTS
	const {
		patientName,
		setPatientName,
		clearFields,
		handleInsertPatientRecord,
	} = usePatientState();

	// FILE UPLOAD
	// file upload provider
	const {
		uploadAudio,
		uploadProgress,
		isUploading
	} = useFileUpload();

	// this state object is used to control the warning/error messages in the upload view of the recording widget.
	// it is currently not implemented (although the recording widget component handles the state), so 
	// TODO: implement recording widget errors and warnings during upload
	const [recordingWidgetUploadState, setRecordingWidgetUploadState] = useState<RecordingWidgetUploadState>(RecordingWidgetUploadState.SUCCESS);

	// REFS
	const { generateOnStopRef } = useRealtimeContextApi();

	const isRecording = useMemo(() => {
		return recordingState === RecordingState.recording || recordingState === RecordingState.paused;
	}, [recordingState]);

	// handle setting generate on stop
	const setGenerateOnStop = useCallback((generate: boolean) => {
		generateOnStopRef.current = generate;
	}, [generateOnStopRef]);

	/**
	 * recording provider handlers
	**/
	// handle recording complete
	const onAudioDataAvailable = async (
		audioFile: File,
		patientRecord: PatientRecord,
		transcriptSectionId: string,
	) => {
		try {
			if (!patientRecord.transcriptions?.id || !patientRecord.id) {
				throw new Error("patientRecord is undefined");
			}
			// upload audio
			const uploadResponse = await uploadAudio(
				audioFile,
				audioFile.name,
				patientRecord.transcriptions.id,
				transcriptSectionId,
				patientRecord
			);

			// update transcript section for table
			if (uploadResponse?.err) {
				throw new Error(uploadResponse?.err);
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	// handle start recording
	const handleStartRecording = useCallback(async (
		{ patientRecordId, patientName }: HandleStartRecordingParams
	) => {
		try {
			// check for recording errors
			if (isRecordingError(recordingState)) {
				console.error("Error starting recording", recordingState);
				resetRecorder();
				return;
			}

			let patientRecord: PatientRecord | null = null;

			if (patientName) {
				setPatientName(patientName);
			}

			if (!patientRecordId) {
				// insert patient record
				patientRecord = await handleInsertPatientRecord(true);

				if (!patientRecord) {
					toast.error("Error starting recording, failed to create patient record");
					return;
				}
			} else {
				// fetch the patient record
				const { data, error } = await fetchPatientRecord(patientRecordId);

				if (error || !data) {
					toast.error("Error starting recording, failed to fetch patient record");
					return;
				}

				patientRecord = data;
			}

			setPatientRecordId(patientRecord.id);

			// start recording with audio recorder hook
			startRecording(
				patientRecord,
				(audioFile: File, patientRecord: PatientRecord, transcriptSectionId: string) =>
					onAudioDataAvailable(audioFile, patientRecord, transcriptSectionId),
			);

			setRecordingWidgetState(RecordingWidgetState.RECORDING);

		} catch (error) {
			Sentry.captureException(error);
			toast.error("An unexpected error occurred while starting the recording");
			resetRecorder();
			setRecordingWidgetState(RecordingWidgetState.WAITING);
		}
	}, [recordingState, resetRecorder, handleInsertPatientRecord, fetchPatientRecord, startRecording, onAudioDataAvailable, setRecordingWidgetState, setPatientRecordId]);

	// handle finish recording
	const handleFinishRecording = async () => {
		stopRecording();

		clearFields();
		setPatientRecordId("");
	}

	// safe toggle pause resume
	const safeTogglePauseResume = useCallback(() => {
		// try to toggle pause/resume
		try {
			togglePauseResume();

			setRecordingWidgetState(
				recordingWidgetState === RecordingWidgetState.PAUSED
					? RecordingWidgetState.RECORDING
					: RecordingWidgetState.PAUSED
			);

		} catch (error) {
			// if an error has occurred, handle it by stopping the recording

			// log the error to sentry
			Sentry.captureException(error);

			// set recording widget state to uploading
			setRecordingWidgetState(RecordingWidgetState.UPLOADING);

			// end the recording
			handleFinishRecording();

			// notify the user of the error
			toast.error("An unexpected error occurred while pausing the recording");
		}

	}, [recordingState, resetRecorder, togglePauseResume]);

	/**
	 * recording provider controls
	**/
	// control the recording hook
	const recorderControlsValue: recorderControls = {
		startRecording,
		stopRecording,
		togglePauseResume: safeTogglePauseResume,
		isRecording,
		recordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		resetRecorder,
		setRecordingState,
	};

	// control the recording widget
	const recordingWidgetControlsValue: recordingWidgetControls = {
		recordingWidgetState,
		setRecordingWidgetState,
		handleStartRecording,
		handleFinishRecording,
		renderWidget,
		setRenderWidget,
	}

	// control the patient data
	const recordingPatientControlsValue: recordingPatientControls = {
		patientName,
		setPatientName,
		patientRecordId,
		setPatientRecordId
	}

	// control the file upload
	const recordingFileUploadControlsValue: recordingFileUploadControls = {
		uploadProgress,
		isUploading,
		recordingWidgetUploadState,
	}

	// control the document selection
	const recordingDocumentSelectControlsValue: recordingDocumentSelectControls = {
		setGenerateOnStop: setGenerateOnStop,
	}

	// render the RecordingWidget with audio recorder provider as context
	// this ensures there is only one recording widget per audio recording provider
	// (and as there is only one instance of audio recording provider, there is only one recording widget)
	return (
		<AudioRecorderContext.Provider value={{
			recorderControls: recorderControlsValue,
			recordingWidgetControls: recordingWidgetControlsValue,
			recordingPatientControls: recordingPatientControlsValue,
			recordingFileUploadControls: recordingFileUploadControlsValue,
			recordingDocumentSelectControls: recordingDocumentSelectControlsValue,
		}}>
			{children}
		</AudioRecorderContext.Provider>
	);
}
