import UploadProgressBar from '@/common/components/UploadProgressBar';
import { RecordingWidgetState, RecordingWidgetUploadState, useAudioRecordingProvider } from '@/state/providers/RecordingProvider';
import ArrowRightIcon from "@icons/arrow-right.svg?react";
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface Props {
    uploadProgress: number;
    newPatientId: string | null;
    setNewPatientId: (patientId: string | null) => void;
}

// view for when widget is in the "uploading" and "saved" states (after recording)
const UploadView: React.FC<Props> = ({ uploadProgress, newPatientId, setNewPatientId }) => {
    /**
     * Audio Recording Provider
     * hook for managing widget state, audio recording, file upload, document select, patient data
    **/
    const {
        // recording widget controls
        recordingWidgetControls: {
            recordingWidgetState,
            setRecordingWidgetState,
            setRenderWidget,

        },
        // file upload controls
        recordingFileUploadControls: {
            recordingWidgetUploadState,

        },
    } = useAudioRecordingProvider();

    const navigate = useNavigate();

    // disable the action buttons if the widget is not in the saved state
    const buttonsDisabled = recordingWidgetState !== RecordingWidgetState.SAVED;


    /**
     * Close the recording widget
     * handler for the close button in the upload view
    */
    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        setRecordingWidgetState(RecordingWidgetState.WAITING);
        setNewPatientId(null);

        // close the widget if not on the home page
        if (location.pathname !== "/") {
            setRenderWidget(false);
        }
    }

    /**
     * Visit the patient
     * handler for the visit button in the upload view
    */
    const handleVisit = (e: React.MouseEvent<HTMLButtonElement>) => {
        setRecordingWidgetState(RecordingWidgetState.WAITING);
        if (newPatientId) {
            navigate(`/appointments/${newPatientId}`);
            setNewPatientId(null);
        }
    }

    return (
        <div className="w-full h-full flex flex-col">
            {/* Progress and text container */}
            <div className="px-6 pt-6 pb-2 flex flex-col justify-start flex-grow">
                <div className="h-2 w-3/4">
                    <UploadProgressBar percentage={uploadProgress} displayPercentage={false} />
                </div>
                <div className="text-left text-white text-sm font-medium leading-tight mt-4">
                    {recordingWidgetState === RecordingWidgetState.UPLOADING && "Saving your recorded clip..."}
                    {recordingWidgetState === RecordingWidgetState.SAVED && "Recorded clip has been saved."}
                </div>
            </div>

            {/* warning and error messages */}
            {recordingWidgetUploadState === RecordingWidgetUploadState.WARNING && (
                <div className="w-full h-[34px] py-2 px-4 bg-error-100">
                    <div className="text-center text-white text-xs font-medium">
                        Warning
                    </div>
                </div>
            )}
            {recordingWidgetUploadState === RecordingWidgetUploadState.ERROR && (
                <div className="w-full h-[34px] py-2 px-4 bg-error-200">
                    <div className="text-center text-white text-xs font-medium">
                        Error
                    </div>
                </div>
            )}

            {/* buttons section */}
            <div className="h-[38px] px-6 py-2 mb-4 flex flex-row justify-between">
                <button
                    className={classNames(
                        "h-[27px] px-2 bg-white rounded-md shadow border border-shadow-500 justify-center items-center gap-1 inline-flex",
                        buttonsDisabled ? "border-shadow-300 text-shadow-300" : "border-shadow-500 text-shadow-500"
                    )}
                    onClick={handleClose}
                    disabled={buttonsDisabled}
                >
                    <div className="text-xs font-semibold leading-[18px]">Close</div>
                </button>
                <button
                    className={classNames(
                        "h-[26px] px-2 bg-white rounded-md shadow border justify-center items-center gap-1 inline-flex",
                        buttonsDisabled ? "border-shadow-300 text-shadow-300" : "border-max-800 text-max-800"
                    )}
                    onClick={handleVisit}
                    disabled={buttonsDisabled}
                >
                    <div className="text-xs font-semibold leading-[18px]">Visit</div>
                    <ArrowRightIcon className="w-4 h-4" />
                </button>
            </div>
        </div>
    );
};

export default UploadView;