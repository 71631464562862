import React from 'react';
import { useAudioRecordingProvider, RecordingWidgetState } from '@/state/providers/RecordingProvider';

interface AppBlurProps {
    children: React.ReactNode;
}

const AppBlur: React.FC<AppBlurProps> = ({ children }) => {
    // use the audio recording provider widget
    const { recordingWidgetControls: {
        recordingWidgetState,
    } } = useAudioRecordingProvider();

    // determine if app should be blurred
    const isBlurred =
        recordingWidgetState === RecordingWidgetState.RECORDING ||
        recordingWidgetState === RecordingWidgetState.PAUSED ||
        recordingWidgetState === RecordingWidgetState.UPLOADING;

    return (
        <div className={isBlurred ? 'blur-sm pointer-events-none' : ''}>
            {children}
        </div>
    );
};

export default AppBlur;
