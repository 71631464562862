import SearchBar from "@common/components/SearchBar";
import CompleteDocuments from "@common/components/CompleteDocuments";
import classNames from "classnames";
import { useAppSelector } from "@/common/hooks/useRedux";
import { useAccountContext } from "@providers/AccountProvider";
import ProfileMenu from "./ProfileMenu";
import { RecordingState, TableTab, VisitPage } from "@/common/utils/types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TableTabs from "@/common/components/HDTabs";

const Navbar = () => {
	const { accountData } = useAccountContext();
	const { globalRecordingState } = useAppSelector((state) => state.globalState);
	const location = useLocation();
	const navigate = useNavigate();

	const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
	const [tabs, setTabs] = useState<TableTab[]>([
		{
			header: "Visits",
			isActive: location.pathname === '/',
			id: '/',
		},
	]);

	useEffect(() => {
		setTabs((prev) =>
			prev.map((t) => {
				if (t.id.split('?')[0] === location.pathname) {
					return { ...t, isActive: true, };
				} else {
					return { ...t, isActive: false, };
				}
			}),
		);
	}, [location.pathname]);

	/**
	 * Change the tab and update the current stage in redux
	 * @param id - The id of the tab
	 */
	const changeTab = (id: string) => {

		if (location.pathname === id.split('?')[0]) {
			return;
		}

		setTabs((prev) =>
			prev.map((t) => {
				if (t.id === id) {
					return { ...t, isActive: true, };
				} else {
					return { ...t, isActive: false, };
				}
			}),
		);

		// nav to list or calendar based on preferences
		const params = new URLSearchParams(location.search);
		if (accountData?.meta_data?.default_view === VisitPage.Calendar) {
			params.set('page', VisitPage.Calendar);
			navigate({ pathname: id, search: params.toString() });
		} else {
			params.set('page', VisitPage.List);
			navigate({ pathname: id, search: params.toString() });
		}
	};

	const toggleSearchBar = () => {
		setIsSearchBarVisible(!isSearchBarVisible);
	};

	const handleLogoClick = () => {
		// navigate to home. note that Visit.tsx manages the correct list vs. calendar page state
		navigate('/');
	}

	const isRecording = globalRecordingState === RecordingState.recording || globalRecordingState === RecordingState.paused;

	return (
		<div className="flex-col bg-gray-100">
			<div
				className={classNames(
					"flex h-16 shrink-0 items-center border-b border-gray-200 bg-gary-100 px-4 "
				)}
			>
				<div className="w-full flex items-center flex-1 gap-x-5 self-stretch">
					<button className="focused" disabled={isRecording} onClick={handleLogoClick}>
						<img src="/happydoc.svg" alt="logo" className="hidden sm:inline h-8 w-auto  " />
						<img src="/favicon.png" alt="logo" className="sm:hidden h-8 w-auto " />
					</button>
					<h1 className="hidden lg:inline-flex text-xl font-semibold leading-6 text-gray-900">{accountData?.clinic_name || ""}</h1>
					{!isSearchBarVisible && <div className=" ">
						<TableTabs tabs={tabs} changeTab={changeTab} disabled={isRecording} paddingVertical="py-5  sm:py-[18px]" />
					</div>}
				</div>

				<div className="flex items-center gap-x-2 ml-auto">
					<div
						className={classNames("flex")}
					>
						<SearchBar isSearchBarVisible={isSearchBarVisible} toggleSearchBar={toggleSearchBar} disabled={isRecording} />
					</div>

					<div className="flex items-center">
						<CompleteDocuments />
					</div>

					<ProfileMenu />
				</div>
			</div>
		</div>

	);
};
export default Navbar;
