import { useState, useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { PatientRecord, RecordingState } from "../utils/types";
import AudioDb, { INDEX_DB_VERSION } from "../classes/audioDb";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import { useAppDispatch } from "@/common/hooks/useRedux";
import { setGlobalRecordingState } from "@/state/redux/globalState";

export const isRecordingError = (recordingState: RecordingState) => {
	return (
		recordingState === RecordingState.error ||
		recordingState === RecordingState.no_device ||
		recordingState === RecordingState.no_permission
	);
};

export const isRecording = (recordingState: RecordingState) => {
	return recordingState === RecordingState.recording || recordingState === RecordingState.paused;
};

/**
 * Checks if the file size is valid
 */
export function isFileSizeValid(size: number): boolean {
	// 150mb
	if (size > 150000000) {
		return false;
	}
	return true;
}

export interface recorderControls {
	startRecording: (
		patientRecord: PatientRecord,
		onAudioDataAvailable: (
			audioFile: File,
			patientRecord: PatientRecord,
			transcriptSectionId: string,
		) => Promise<void>,
	) => any;
	stopRecording: () => void;
	togglePauseResume: () => void;
	isRecording: boolean;
	recordingState: RecordingState;
	recordingTime: number;
	audioStream?: MediaStream;
	activeRecordingId: string;
	setActiveRecordingId: React.Dispatch<React.SetStateAction<string>>;
	resetRecorder: () => void;
	setRecordingState: React.Dispatch<React.SetStateAction<RecordingState>>;
}

export type MediaAudioTrackConstraints = Pick<
	MediaTrackConstraints,
	| "deviceId"
	| "groupId"
	| "autoGainControl"
	| "channelCount"
	| "echoCancellation"
	| "noiseSuppression"
	| "sampleRate"
	| "sampleSize"
>;

/**
 * @returns Controls for the recording. Details of returned controls are given below
 *
 * @param `audioTrackConstraints`: Takes a {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings#instance_properties_of_audio_tracks subset} of `MediaTrackConstraints` that apply to the audio track
 * @param `mediaRecorderOptions`: A {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/MediaRecorder MediaRecorderOptions} object that is passed to the MediaRecorder constructor
 *
 * @details `startRecording`: Calling this method would result in the recording to start. Sets `isRecording` to true
 * @details `stopRecording`: This results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
 * @details `togglePauseResume`: Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
 * @details `recordingState`: This is the current state of the recording. It can be one of `recording`, `paused`, `inactive` or `error`
 * @details `recordingTime`: Number of seconds that the recording has gone on. This is updated every second
 */

const useAudioRecorder: (
	audioTrackConstraints?: MediaAudioTrackConstraints,
	mediaRecorderOptions?: MediaRecorderOptions,
) => recorderControls = (audioTrackConstraints, mediaRecorderOptions) => {
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);
	const dispatch = useAppDispatch();
	const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.inactive);
	const [recordingTime, setRecordingTime] = useState(0);
	const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
	const [audioStream, setAudioStream] = useState<MediaStream>();
	const [mimeType, setMimeType] = useState(mediaRecorderOptions ? mediaRecorderOptions.mimeType : "audio/webm");
	const [activeRecordingId, setActiveRecordingId] = useState<string>("");
	const timerIntervalRef = useRef<NodeJS.Timeout | undefined>();
	const hourIntervalRef = useRef<NodeJS.Timeout | undefined>();
	const patientRecordRef = useRef<PatientRecord | null>(null);
	const onAudioDataAvailableRef = useRef<any>(null);
	const hourCounterRef = useRef<number>(0);

	// set mimeType based on supported options in browser on mount
	useEffect(() => {
		const options = ["audio/webm", "audio/mp3", "audio/wav", "audio/mp4"];
		const chosenOption = options.find((option) => MediaRecorder.isTypeSupported(option));
		if (!chosenOption) {
			Sentry.captureMessage("Browser does not support audio recording");
			toast.error("This browser does not support the correct audio formats. Contact support");
			setRecordingState(RecordingState.no_device);
			dispatch(setGlobalRecordingState(RecordingState.no_device));
			return;
		}

		setMimeType(chosenOption);
	}, []);

	// handle refresh or tab close while recording or paused to stop recording
	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			if (recordingState === RecordingState.recording || recordingState === RecordingState.paused) {
				// stop stream no matter what on refresh. Comment if we want to allow cancel of refresh while recording
				//mediaRecorder?.stream.getAudioTracks().forEach((t) => t.stop());

				event.preventDefault();

				// onload cancel event do nothing
				if (event?.cancelable) {
					return;
				}
				mediaRecorder?.stream.getAudioTracks().forEach((t) => t.stop());

				event.returnValue = "";
			}
		};

		let isOnIOS = navigator?.userAgent?.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
		let eventName = isOnIOS ? "pagehide" : "beforeunload";
		window.addEventListener(eventName, handleBeforeUnload);

		// Cleanup function
		return () => {
			window.removeEventListener(eventName, handleBeforeUnload);
		};
	}, [recordingState]);

	const _startTimer: () => void = useCallback(() => {
		const interval = setInterval(() => {
			setRecordingTime((time) => time + 1);
		}, 1000);
		timerIntervalRef.current = interval;
	}, [setRecordingTime]);

	const _stopTimer: () => void = useCallback(() => {
		if (timerIntervalRef.current != null) {
			clearInterval(timerIntervalRef.current as any);
			timerIntervalRef.current = undefined;
		}
	}, []);

	/**
	 * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
	 */
	const stopRecording = () => {
		toast.isActive("recording-info") && toast.dismiss("recording-info");
		toast.isActive("recording-silence") && toast.dismiss("recording-silence");
		_stopTimer();
		clearInterval(hourIntervalRef.current as any);
		setRecordingTime(0);
		setActiveRecordingId("");
		patientRecordRef.current = null;
		onAudioDataAvailableRef.current = null;
		hourCounterRef.current = 0;
		// reset recorder
		mediaRecorder?.stop();
		mediaRecorder?.stream.getTracks().forEach((t) => t.stop());

		setRecordingState(RecordingState.inactive);
		dispatch(setGlobalRecordingState(RecordingState.inactive));
		setMediaRecorder(undefined);
		setAudioStream(undefined);
	};

	const stopErroredRecording = (error: string) => {
		_stopTimer();
		clearInterval(hourIntervalRef.current as any);

		setRecordingTime(0);
		setRecordingState(RecordingState.error);
		dispatch(setGlobalRecordingState(RecordingState.error));
		setActiveRecordingId("");
		patientRecordRef.current = null;
		onAudioDataAvailableRef.current = null;
		toast.isActive("recording-info") && toast.dismiss("recording-info");
		toast.isActive("recording-silence") && toast.dismiss("recording-silence");
		toast.error(error, {
			autoClose: false,
			closeOnClick: true,
			draggable: true,
			toastId: "recording-error",
		});
	};

	/**
	 * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
	 * @description Resets the recorder
	 * @returns `void`
	 * @description Resets the recorder
	 */
	const resetRecorder = () => {
		setRecordingState(RecordingState.loading);
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(async (stream) => {
				const recorder = new MediaRecorder(stream, { mimeType: mimeType });

				if (!recorder) {
					setRecordingState(RecordingState.error);
					dispatch(setGlobalRecordingState(RecordingState.error));
					return;
				}

				recorder.stop();

				recorder.ondataavailable = (event: BlobEvent) => {
					recorder.stream.getTracks().forEach((t) => t.stop());
					setRecordingState(RecordingState.inactive);
					dispatch(setGlobalRecordingState(RecordingState.inactive));
				};
				recorder.stream.getTracks().forEach((t) => t.stop());

				setRecordingState(RecordingState.inactive);
				dispatch(setGlobalRecordingState(RecordingState.inactive));
				patientRecordRef.current = null;
				onAudioDataAvailableRef.current = null;
				toast.isActive("recording-info") && toast.dismiss("recording-info");
				toast.isActive("recording-silence") && toast.dismiss("recording-silence");
			})
			.catch((err: DOMException) => {
				// handle error
				err?.message === "Permission denied"
					? toast.error("Please allow microphone access in your browser settings.")
					: err?.message === "Requested device not found"
						? toast.error("Microphone not found. Check that your microphone is enabled and refresh.")
						: toast.error("Error setting up microphone");
				Sentry.captureException(err);
				setRecordingState(RecordingState.error);
				dispatch(setGlobalRecordingState(RecordingState.error));
			});
	};

	// every hour  stop and restart the recorder
	useEffect(() => {
		const interval = setInterval(
			() => {
				if (recordingState === RecordingState.recording) {
					// limit to 3 hours
					if (hourCounterRef.current >= 2) {
						clearInterval(interval);
						toast.info("Recording stopped after 3 hours.");

						// stop recording
						if (recordingState === RecordingState.recording) {
							stopRecording();
						}

						hourCounterRef.current = 0;
						return;
					}

					if (patientRecordRef.current && onAudioDataAvailableRef.current) {
						mediaRecorder?.stop();
						mediaRecorder?.stream.getTracks().forEach((t) => t.stop());

						toast.isActive("recording-silence") && toast.dismiss("recording-silence");
						toast.info("Last hour of recording saving...");

						setMediaRecorder(undefined);
						setAudioStream(undefined);
						_stopTimer();
						startRecording(patientRecordRef.current as PatientRecord, onAudioDataAvailableRef.current);
					}

					hourCounterRef.current++;
				}
			},
			1000 * 60 * 60, // 1 hour
		);

		hourIntervalRef.current = interval;

		return () => {
			clearInterval(interval);
		};
	}, [recordingState]);

	/**
	 * Calling this method would result in the recording to start. Sets `isRecording` to true
	 */
	function startRecording(
		patientRecord: PatientRecord,
		onAudioDataAvailable: (
			audioFile: File,
			patientRecord: PatientRecord,
			transcriptSectionId: string,
		) => Promise<void>) {
		if (timerIntervalRef.current != null) return;
		setRecordingState(RecordingState.loading);

		navigator.mediaDevices
			.getUserMedia({ audio: audioTrackConstraints ?? true })
			.then(async (stream) => {
				if (patientRecordRef.current === null) patientRecordRef.current = patientRecord;
				if (onAudioDataAvailableRef.current === null) onAudioDataAvailableRef.current = onAudioDataAvailable;

				setAudioStream(stream);

				const audioContext = new AudioContext();

				if ("audioWorklet" in audioContext) {
					try {
						await audioContext.audioWorklet.addModule("worklets/silence-detector-processor.js");
						const source = audioContext.createMediaStreamSource(stream);
						const silenceDetector = new AudioWorkletNode(audioContext, "silence-detector-processor");
						silenceDetector.port.onmessage = (event) => {
							if (event.data.silent) {
								toast.warn("Low audio detected - try talking, this will disappear if the microphone can hear you.", {
									autoClose: false,
									closeOnClick: true,
									draggable: true,
									toastId: "recording-silence",
								});
							} else {
								toast.isActive("recording-silence") && toast.dismiss("recording-silence");
							}
						};

						source.connect(silenceDetector).connect(audioContext.destination);
					} catch (error) {
						// do nothing to avoid sentry errors
						if (audioContext && audioContext?.state !== 'closed') {
							audioContext?.close();
						}
					}
				} else {
					Sentry.captureMessage("Browser does not support audioWorklet");
				}

				const recorder: MediaRecorder = new MediaRecorder(stream, { ...mediaRecorderOptions, mimeType: mimeType });

				setMediaRecorder(recorder);
				setRecordingState(RecordingState.recording);

				dispatch(setGlobalRecordingState(RecordingState.recording));

				recorder.start();

				_startTimer();

				toast.info("Please do not close this tab, refresh or navigate away from this page while recording", {
					autoClose: 5000,
					closeOnClick: true,
					draggable: true,
					toastId: "recording-info",
				});

				toast.isActive("recording-error") && toast.dismiss("recording-error");

				// set active recording id
				if (activeRecordingId === "") setActiveRecordingId(patientRecord.id as string);

				// handle track events
				stream.getTracks().forEach((t) => {
					if (t.muted) {
						toast.warning("Microphone is muted. Please unmute to record audio");
					}

					t.onmute = (e) => {
						Sentry.captureMessage("Microphone muted by browser", { extra: { event: e }, level: "warning" });
						recorder.stop();
						stopErroredRecording(
							"Microphone muted by browser. Audio recording stopped and is being processed. See Help Center for trouble shooting articles.",
						);
					};
					t.onended = (e) => {
						Sentry.captureMessage("Microphone ended by browser", { extra: { event: e }, level: "warning" });
						recorder.stop();
						stopErroredRecording(
							"Microphone ended by browser. Audio recording stopped and is being processed. See Help Center for trouble shooting articles.",
						);
					};
				});
				// handle recorder events
				recorder.onerror = (e) => {
					Sentry.captureException(e, {
						extra: { patientRecord, recordingState, recordingTime, activeRecordingId, mimeType },
					});
					stopRecording();
					toast.error(
						"Microphone error occurred. Audio recording stopped and is being processed. See Help Center for trouble shooting articles.",
						{
							autoClose: false,
							closeOnClick: true,
							draggable: true,
							toastId: "recording-error",
						},
					);
				};

				recorder.ondataavailable = async (event: BlobEvent) => {
					if (audioContext && audioContext?.state !== 'closed') {
						audioContext?.close()
					}

					if (event.data.size > 0) {
						if (!patientRecord.transcriptions?.id || !patientRecord.id) return;

						// create and check file
						const audioFile = createAudioFile(event.data);

						const transcriptSectionId = uuidv4();

						if (isFileSizeValid(audioFile.size)) {
							// add to local db
							localAudioDb.addTranscriptSectionAudio(
								patientRecord.transcriptions.id,
								transcriptSectionId,
								event.data,
								audioFile.name,
								0,
								patientRecord.id,
								patientRecord?.patient_name || "",
								patientRecord?.doctors?.id || "",
							);
						}

						// handle audio data
						await onAudioDataAvailable(audioFile, patientRecord, transcriptSectionId);
					} else {
						// audio stream ended without data
						toast.error("No audio data was recorded");
					}
				};
			})
			.catch((err: DOMException) => {
				// handle error
				err?.message === "Permission denied"
					? toast.error("Please allow microphone access in your browser settings.")
					: err?.message === "Requested device not found"
						? toast.error("Microphone not found. Check that your microphone is enabled and refresh.")
						: toast.error("Error setting up microphone");
				setRecordingState(RecordingState.no_permission);
				dispatch(setGlobalRecordingState(RecordingState.no_permission));
			});
	}

	/**
	 * Creates a file from the audioBlob
	 */
	function createAudioFile(audioBlob: Blob): File {
		const timeNow = new Date().toISOString();
		const mimeType = audioBlob.type;
		const audioName = `audio-${timeNow}-${Math.floor(Math.random() * 1000)}.${mimeType.split("/")[1].split(";")[0]}`;

		return new File([audioBlob], audioName, { type: mimeType });
	}

	/**
	 * Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
	 */
	const togglePauseResume: () => void = useCallback(() => {
		// check if media recorder is inactive
		if (!mediaRecorder || mediaRecorder.state === "inactive") {
			stopRecording();
			throw new Error("MediaRecorder is inactive");
		}

		if (recordingState === RecordingState.paused) {
			setRecordingState(RecordingState.recording);
			mediaRecorder?.resume();
			_startTimer();
		} else {
			setRecordingState(RecordingState.paused);
			toast.isActive("recording-silence") && toast.dismiss("recording-silence");
			_stopTimer();
			mediaRecorder?.pause();
		}
	}, [mediaRecorder, recordingState, setRecordingState, _startTimer, _stopTimer, toast]);

	return {
		startRecording,
		stopRecording,
		togglePauseResume,
		isRecording: isRecording(recordingState),
		recordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		resetRecorder,
		setRecordingState,
	};
};

export default useAudioRecorder;
