// external libraries
import * as Sentry from "@sentry/react";
import { useSessionContext } from "@supabase/auth-helpers-react";
import { Suspense, useEffect, useState } from "react";
import { createBrowserRouter, createRoutesFromChildren, RouterProvider } from "react-router-dom";

// utils and constants
import { VITE_PUBLIC_ENV } from "@common/utils/constants";

// common components
import Spinner from "@/common/components/Spinner";
import AppBanner from "@/common/components/AppBanner";

// routes
import { UnAuthenticatedRoutes } from "../routes/UnAuthenticatedRoutes";
import AuthenticatedRoutes from "../routes/AuthenticatedRoutes";

declare global {
	interface Window {
		pendo: any;
	}
}

function App() {
	const { session, supabaseClient, isLoading } = useSessionContext();
	const [isIOS, setIsIOS] = useState(false);

	const router = createBrowserRouter(
		createRoutesFromChildren(session ? AuthenticatedRoutes() : UnAuthenticatedRoutes()),
	);

	useEffect(() => {
		const { data: authListener } = supabaseClient.auth.onAuthStateChange((event, session) => {
			if (!session && event !== "INITIAL_SESSION") {
				window.location.reload();
			}
		});

		const userAgent = window.navigator.userAgent.toLowerCase();
		const isIOSDevice = /iphone|ipad|ipod/.test(userAgent);
		setIsIOS(isIOSDevice);

		return () => {
			authListener.subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (
			session &&
			VITE_PUBLIC_ENV === "production" &&
			!session?.user?.email?.endsWith("@gohappydoc.com") &&
			!session?.user?.email?.endsWith("@test.com")
		) {
			try {
				window.pendo.initialize({
					visitor: {
						id: session?.user?.email,
						accountId: session?.user?.id,
					},
					account: {
						id: session?.user?.email,
						accountId: session?.user?.id,
					},
				});
			} catch (e: any) {
				Sentry.captureException(e);
			}
		}
	}, [session]);

	if (isLoading) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<Spinner />
			</div>
		);
	}

	return (
		<Suspense
			fallback={
				<div className="w-full h-full flex items-center justify-center">
					<Spinner />
				</div>
			}
		>
			{isIOS && <AppBanner />}
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;
